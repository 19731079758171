import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import MagneticToCursor from './animations/MagneticToCursor';
import Cursor from './cursor/Cursor';
import { ToggleMenu, MobileInnerMenu, DesktopMenuChild, DesktopMenuParent } from './menu';
import VideoModal from './modals/VideoModal';
import BackgroundAnimations from './animations/BackgroundAnimations';
import objectFitImages from 'object-fit-images';
import polyfills from './polyfills';
import ImageShuffle from './animations/ImageShuffle';
polyfills();
var menu = null;
var standardHeader = document.querySelector('.header'); //Mobile Inner Menu

var initiateMobileMenus = function initiateMobileMenus() {
  var menuButton = document.querySelector('.hamburger');
  var fullHeightDrawer = document.querySelector('.header__nav');

  if (menuButton && standardHeader) {
    menu = new ToggleMenu(standardHeader, menuButton, fullHeightDrawer);
    menu.init();
  }
}; //Mobile Inner Menu


var initiateMobileDropdowns = function initiateMobileDropdowns() {
  var headerItems = document.querySelectorAll('.header__item');

  if (headerItems.length > 0) {
    var allItems = [];
    headerItems.forEach(function (item) {
      var link = item.querySelector('.header__link');
      var dropdown = item.querySelector('.mega-menu');
      var settings = {
        directLinkClass: 'header__link-text',
        breakpoint: 1024
      };

      if (link && dropdown) {
        var newMobileDropdowns = new MobileInnerMenu(link, dropdown, allItems, settings);
        newMobileDropdowns.init();
        allItems.push(newMobileDropdowns);
      }
    });
  }
}; //Desktop Mega Menu


var initiateDesktopMenu = function initiateDesktopMenu() {
  if (standardHeader) {
    var headerLinks = standardHeader.querySelectorAll('.header__link');
    var headerSubLinks = standardHeader.querySelectorAll('.mega-menu__link');

    if (headerLinks.length > 0) {
      headerLinks.forEach(function (headerItem) {
        var headerLinkObject = new DesktopMenuParent(headerItem);
        headerLinkObject.init();
      });
    }

    if (headerSubLinks.length > 0) {
      headerSubLinks.forEach(function (headerSubItem) {
        var headerSubLinkObject = new DesktopMenuChild(headerSubItem);
        headerSubLinkObject.init();
      });
    }
  }
};

var allMagnets = [];

var magnetListing = function magnetListing() {
  var cards = document.querySelectorAll('.magnet-link');
  var startingBreakpoint = 1024;
  cards.forEach(function (card) {
    var newMagnet = new MagneticToCursor(card, startingBreakpoint);
    newMagnet.init();
    allMagnets.push(newMagnet);
  });
}; //Cursor hover


var initiateCursor = function initiateCursor() {
  var cursorElement = document.querySelector('.cursor');

  if (cursorElement) {
    var cursorHover = 'cursor--hover';
    var cursorLinks = document.querySelectorAll('.image-shuffle__inner, .product-slider__image, .simple-card');
    var dynamicCursor = new Cursor(cursorElement, cursorHover, cursorLinks);
    dynamicCursor.init();
  }
}; //Video Modal


var initiateVideoModal = function initiateVideoModal() {
  var videoBtns = ['js-play-button'];
  var videoModal = 'videoModal';

  if (document.getElementById('videoModal')) {
    var video = new VideoModal(videoBtns, videoModal);
    video.init();
  }
}; //Animate svgs


var initiateAnimatedSvgs = function initiateAnimatedSvgs() {
  var fullRights = document.querySelectorAll('.page-strip--full-right .page-strip__graphic svg path');
  var fullRightStates = ['M840.285988,354.78248 C566.699893,431.483175 232.904197,470.568714 64.9510961,727.397525 C-14.3100917,851.251846 -27.4928257,1024.62381 61.6617505,1141.56987 C359.895755,1492.50312 1233.08709,1111.36367 1506.37531,847.487161 C1722.50243,657.788887 1897.49688,124.813489 1548.39527,0.959168941 C1340.50102,-18.9923919 1113.87208,278.081785 840.285988,354.78248 Z', 'M846.248268,276.45108 C572.662173,353.151775 183.911818,243.349076 15.9587176,500.177887 C-63.3024702,624.032208 173.856876,888.75795 263.011452,1005.70401 C561.245456,1356.63726 1276.80508,1077.92528 1550.0933,814.048776 C1766.22042,624.350502 1835.7908,125.136124 1486.68919,1.28180394 C1278.79494,-18.6697569 1119.83436,199.750385 846.248268,276.45108 Z'];

  if (fullRights.length > 0) {
    var newFullRights = new BackgroundAnimations(fullRights, fullRightStates);
    newFullRights.init();
  }

  var fullLefts = document.querySelectorAll('.page-strip--full-left .page-strip__graphic svg path');
  var fullLeftStates = ['M245.395792,23.3415603 C114.348736,65.7855772 2.92955083,183.520576 0,321.425613 C13.8227401,753.565112 880.660079,998.3376 1236.78217,970.198493 C1507.2797,961.699122 1948.85879,679.976355 1764.34901,381.999451 C1622.90658,243.52964 1469.50245,440.509523 1035.14559,215.914864 C788.610057,108.277165 519.787438,-62.5278139 245.395792,23.3415603 Z', 'M372.389527,16.5145538 C241.342471,58.9585708 2.92955083,302.299618 0,440.204656 C13.8227401,872.344155 786.634523,952.604749 1142.75661,924.465642 C1413.25415,915.966271 1866.32988,544.920192 1681.8201,246.943289 C1540.37767,108.473477 1436.77226,534.565193 1002.41539,309.970534 C755.87986,202.332835 646.781173,-69.3548204 372.389527,16.5145538 Z'];

  if (fullLefts.length > 0) {
    var newFullLefts = new BackgroundAnimations(fullLefts, fullLeftStates);
    newFullLefts.init();
  }

  var fullLeftRegs = document.querySelectorAll('.page-strip--full-left-reg .page-strip__graphic svg path');
  var fullLeftRegStates = ['M906.489583,354.78248 C947.527497,366.287584 989.920127,376.946347 1032.90455,387.620604 L1058.76011,394.033912 C1294.86883,452.608851 1544.10293,516.7979 1681.82447,727.397525 C1761.08566,851.251846 1774.2684,1024.62381 1685.11382,1141.56987 C1386.87982,1492.50312 513.688481,1111.36367 240.400261,847.487161 C24.2731409,657.788887 -150.721309,124.813489 198.380301,0.959168943 C308.564253,-9.6151583 424.01078,68.8630442 548.921557,156.387393 L570.224553,171.322459 C674.824727,244.621562 786.111702,321.034174 906.489583,354.78248 Z', 'M961.489463,354.78248 C1002.52738,366.287584 1044.92001,376.946347 1087.90443,387.620604 L1113.75999,394.033912 C1349.86871,452.608851 1647.63666,331.887721 1785.3582,542.487346 C1864.61939,666.341667 1652.41919,1096.64394 1563.26461,1213.59 C1265.03061,1564.52325 444.171042,1109.4143 170.882822,845.537795 C-45.2442976,655.839521 -95.721429,124.813489 253.380181,0.959168943 C363.564134,-9.6151583 479.01066,68.8630442 603.921437,156.387393 L625.224433,171.322459 C729.824607,244.621562 841.111583,321.034174 961.489463,354.78248 Z'];

  if (fullLeftRegs.length > 0) {
    var newFullLeftRegs = new BackgroundAnimations(fullLeftRegs, fullLeftRegStates);
    newFullLeftRegs.init();
  }

  var halfRights = document.querySelectorAll('.page-strip--half-right .page-strip__graphic svg path');
  var halfRightStates = ['M64.472597,679.213861 C116.012436,724.232765 189.381106,734.812982 246.431135,772.634405 C426.808346,899.47474 282.451149,1048.12353 363.905778,1163.12837 C484.689795,1265.21768 658.370821,1170.45219 748.563502,1069.03943 C1241.41169,594.127887 859.855484,-451.593691 191.736793,217.156714 C52.873484,316.336059 -84.4777835,534.872966 64.472597,679.213861 Z', 'M93.615741,597.227737 C145.15558,642.246641 241.877778,681.244751 298.927807,719.066174 C479.305018,845.906509 132.343927,981.591816 213.798556,1096.59666 C334.582573,1198.68597 641.381389,1132.11898 731.57407,1030.70622 C1224.42226,555.794681 984.761156,-329.248298 125.807856,126.777927 C-13.0554532,225.957272 -55.3346395,452.886842 93.615741,597.227737 Z'];

  if (halfRights.length > 0) {
    var newHalfRights = new BackgroundAnimations(halfRights, halfRightStates);
    newHalfRights.init();
  }

  var productMorphs = document.querySelectorAll('.product-list__graphics svg path');
  var productMorphStates = ['M-1.13686838e-13,1550 C55.0736844,1473.39539 135.990656,1418.43161 248.442891,1398.40165 C375.52842,1382.49011 500.441136,1442.85958 628.543634,1443.88257 C1041.16711,1435.85395 972.752925,1054.12796 1219.50018,960.933486 C1302.29328,951.931629 1377.11157,971.493395 1440,1009.86539 L1440,1550 L-1.13686838e-13,1550 Z', 'M-1.13686838e-13,1550 C55.0736844,1473.39539 193.962316,1336.02996 306.414551,1316 C433.50008,1300.08846 619.090373,1488.59221 747.192871,1489.6152 C1159.81635,1481.58659 853.252748,1053.19448 1100,960 C1182.7931,950.998143 1377.11157,971.493395 1440,1009.86539 L1440,1550 L-1.13686838e-13,1550 Z'];

  if (productMorphs.length > 0) {
    var newProductMorphs = new BackgroundAnimations(productMorphs, productMorphStates);
    newProductMorphs.init();
  }

  var innerMorphs = document.querySelectorAll('.inner__graphics svg path');
  var innerMorphStates = ['M0,253 L0.998013052,160.535461 C98.3509247,49.2941022 230.188469,-14.9736118 397.031613,23.3628457 C519.986802,58.4153878 611.199737,162.921023 728.48549,213.978842 C1100.85767,364.042149 1190.43947,8.53958586 1438.13681,0 C1444.67283,-0.224718945 1431.37483,252.726487 1438.13681,253 L0,253 Z', 'M0,253 L0.998013052,160.535461 C98.3509247,49.2941022 313,23.3628457 497,23.3628457 C664.871267,23.3628457 766.5,160.535461 896.75,184.359863 C1263.94063,235.170959 1200,64.4494019 1438.13681,0.000600558197 C1441.76433,-0.981144518 1431.37483,252.726487 1438.13681,253 L0,253 Z'];

  if (innerMorphs.length > 0) {
    var newInnerMorphs = new BackgroundAnimations(innerMorphs, innerMorphStates);
    newInnerMorphs.init();
  }

  var waveMorphs = document.querySelectorAll('.page-strip--wave .page-strip__graphic svg path');
  var waveMorphStates = ['M0 354V151.605C124.08 23.869 287.37-44.823 472.633 33.436 587.882 89.307 659.763 207.97 766.64 278.597c309.413 192.483 464.26-63.625 673.359-87.507V354H0Z', 'M0 329V126.605C124.08-1.13 273.94-36.048 459.202 42.212c115.25 55.87 217.213 95.962 324.092 166.59 309.412 192.482 559.642 26.43 656.706-42.712V329H0Z'];

  if (waveMorphs.length > 0) {
    var newWaveMorphs = new BackgroundAnimations(waveMorphs, waveMorphStates);
    newWaveMorphs.init();
  }

  var profileTopMorphs = document.querySelectorAll('.full-page__graphic--top svg path');
  var profileTopMorphStates = ['M459.641332,141.046604 C499.849065,194.140438 521.90006,257.038649 542.686703,320.859087 C571.341969,406.618277 593.881093,463.986569 610.304074,492.963962 C733.997614,697.894263 884.941717,701.547913 1029.73415,701.068958 C1170.10432,701.389721 1267.50039,723.829056 1321.92236,768.386963 C1537.48608,994.512877 1299.25839,1314.00207 1098.14329,1469.15397 C785.996529,1750.82251 369.015727,1848.5872 2.27373675e-13,1811.80627 L2.27373675e-13,26.4966874 C163.677661,-24.7982865 334.18768,-7.93656042 459.641332,141.046604 Z', 'M474.662964,112.224426 C514.870697,165.31826 521.90006,271.038649 542.686703,334.859087 C548.377127,427.108326 559.43383,487.721642 575.856812,516.699036 C699.550351,721.629337 853.219774,754.971692 998.012207,754.492737 C1127.96165,683.652311 1220.14736,670.511051 1274.56934,715.068958 C1490.13306,941.194872 1358.34216,1359.19722 1157.22705,1514.34912 C845.080294,1796.01766 369.015727,1862.5872 4.54747351e-13,1825.80627 L4.54747351e-13,40.4966874 C163.677661,-10.7982865 349.209312,-36.7587381 474.662964,112.224426 Z'];

  if (profileTopMorphs.length > 0) {
    var newProfileTopMorphs = new BackgroundAnimations(profileTopMorphs, profileTopMorphStates);
    newProfileTopMorphs.init();
  }

  var profileBottomMorphs = document.querySelectorAll('.full-page__graphic--bottom svg path');
  var profileBottomMorphStates = ['M1440,372.279763 C1354.10178,186.494383 1203.95252,46.933967 979.848048,82.0294337 C854.506029,108.359778 762.165771,236 635.262873,248.781183 C284.722168,295.475891 199.085556,72.3450583 -2.27373675e-13,0.256083536 L-2.27373675e-13,1446 L1440,1446 L1440,372.279763 Z', 'M1440,372.279763 C1354.10178,186.494383 1277.66453,25.9468917 1053.56006,61.0423584 C928.218039,87.3727026 691.873968,271.218817 564.971069,284 C214.430364,330.694708 199.085556,72.3450583 -2.27373675e-13,0.256083536 L-2.27373675e-13,1446 L1440,1446 L1440,372.279763 Z'];

  if (profileBottomMorphs.length > 0) {
    var newProfileBottomMorphs = new BackgroundAnimations(profileBottomMorphs, profileBottomMorphStates);
    newProfileBottomMorphs.init();
  }
};

var initiateImageShuffle = function initiateImageShuffle() {
  var imageWrappers = document.querySelectorAll('.image-shuffle__outer');

  if (imageWrappers.length > 0) {
    imageWrappers.forEach(function (wrapper) {
      var newImageShuffle = new ImageShuffle(wrapper, imageWrappers);
      newImageShuffle.init();
    });
  }
};

window.addEventListener('DOMContentLoaded', function () {
  objectFitImages();
  initiateCursor();
  magnetListing();
  initiateMobileMenus();
  initiateMobileDropdowns();
  initiateDesktopMenu();
  initiateVideoModal();
  initiateAnimatedSvgs();
  initiateImageShuffle();
});
window.addEventListener('resize', function () {
  if (menu !== null) {
    menu.onResize();
  }

  if (allMagnets.length > 0) allMagnets.forEach(function (magnet) {
    return magnet.init();
  });
});
var isScrolling;
var scrollIndicator = document.querySelector('.scroll-indicator');
window.addEventListener('scroll', function () {
  if (allMagnets.length > 0) allMagnets.forEach(function (magnet) {
    return magnet.init();
  });
  window.clearTimeout(isScrolling);

  if (scrollIndicator) {
    scrollIndicator.classList.add('scrolling');
  }

  isScrolling = setTimeout(function () {
    if (scrollIndicator) {
      scrollIndicator.classList.remove('scrolling');
    }
  }, 66);
}, {
  passive: true
});