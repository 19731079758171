import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
export default (function () {
  if (!window.hasOwnProperty('fetch')) {
    window.fetch = function fetch(url, opts) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = Boolean(opts.credentials);
        xhr.open(opts.method || 'GET', url);

        for (var k in opts.headers) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }

        xhr.onload = function (e) {
          resolve({
            status: e.target.status,
            headers: {
              get: function get(name) {
                return e.target.getResponseHeader(name);
              }
            },
            arrayBuffer: function arrayBuffer() {
              return Promise.resolve(e.target.response);
            },
            blob: function blob() {
              return Promise.resolve(new Blob([e.target.response], {
                type: e.target.getResponseHeader('Content-Type')
              }));
            },
            text: function text() {
              return Promise.resolve(e.target.responseText);
            },
            json: function json() {
              return Promise.resolve(JSON.parse(e.target.responseText));
            }
          });
        };

        xhr.onerror = reject;
        xhr.send(opts.body);
      });
    };
  }
});